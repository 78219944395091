import { useEffect } from "react";
import { icons, images } from "../../../../utils/images/images";
import "./LeftScreen.scss";
import PrimaryBtn from "../../../../components/primaryBtn/PrimaryBtn";

const LeftScreen = ({ side, setSide, t }) => {
  return (
    <section className="benefit_suite_left p_top p_bottom">
      <div className="content_wrap">
        <div className={`text_content ${side ? "goleft" : "original"}`}>
          <h2 className="wow fadeInUp">{t("homeLeftTitle")}</h2>
          <p className="wow fadeInUp">{t("homeLeftPara")}</p>
          <PrimaryBtn
            title={t("homeLeftBtn")}
            classList="wow fadeInUp"
            clickHandler={() => setSide(true)}
            delay="0.1s"            
          />
        </div>
        <div className="box_card_side">
          <div className="box_card">
            <div className="btn_block">
              <button type="button" onClick={() => setSide(true)}>
                <img src={icons.whiteArrow} alt="arrow" />
              </button>
              <h6>{t("goRightBtn")}</h6>
            </div>
            <div className="overlay"></div>
            <img src={images.left} className="bg" alt="bg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftScreen;
